import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faGlobeAsia, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
import Image from '../../components/Image'
import ContactInfo from '../../data/contact-data.yaml'

const Footer = () => (
  <footer
    className="footer md:mt-32 mt-10 pt-1 bg-gradient-to-b from-gray-200 via-white border-b-8 border-primary-500"
    style={{ boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.10)' }}
  >
    <div className="container mx-auto px-6">
      <div className="md:flex md:mt-8 mt-4">
        <div className="flex md:flex-row flex-col md:w-full md:px-8 px-1 md:space-y-0 space-y-4 justify-between">
          <div className="flex flex-col space-y-4">
            <span className="font-bold text-gray-700 uppercase md:mt-0 mt-4 md:mb-2 mb-0">
              Products & Services
            </span>
            <Link to="/vizirail">ViziRail</Link>
            <Link to="/dtpos">DTPOS</Link>
            <Link to="/webportal">ViziRail Web Gateway</Link>
          </div>

          <div className="flex flex-col space-y-4">
            <span className="font-bold text-gray-700 uppercase md:mt-0 mt-4 md:mb-2 mb-0">
              Company
            </span>
            <Link to="/about">About Us</Link>
            <Link to="/careers">Careers</Link>
            <Link to="/privacy-policy">Privacy Policy</Link> 
          </div>

          <div className="flex flex-col space-y-4">
            <span className="font-bold text-gray-700 uppercase md:mt-0 mt-4 md:mb-2 mb-0">
              Contact
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${ContactInfo.email}`}>{ContactInfo.email}</a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href={`tel:${ContactInfo.telephone}`}>{ContactInfo.telephone}</a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faGlobeAsia} />
              <a href={`tel:${ContactInfo.international}`}>{ContactInfo.international}</a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faLinkedin} />
              <a href={ContactInfo.linkedin}>Linkedin</a>
            </span>
          </div>

          <div className="flex flex-col">
            <div className="w-32 md:mx-auto md:mt-0 mt-4 mb-1 logo-highlight">
              <Link to="/">
                <Image src="logo.PNG" alt="Vizirail" disableLightBox={true} />
              </Link>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="flex-col">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="text-md font-medium text-black">
                <span className="flex">{ContactInfo.address.level}</span>
                <span className="flex">{ContactInfo.address.street}</span>
                <span className="flex">{ContactInfo.address.suburb}</span>
                <span className="flex">{ContactInfo.address.state}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto px-6">
      <div className="flex md:flex-row flex-col-reverse mt-12 px-4 pb-2 border-t border-gray-300 items-center justify-between">
        <div className="flex flex-col w-16 md:mb-1 mb-3">
          <Image src="logo-icgtransport.png" alt="icg-transport" disableLightBox={true} />
        </div>
        <div className="flex flex-col text-center py-4">
          <p className="text-sm text-gray-600 font-medium md:mb-2">
            © 2023 ICG Transport Systems Pty Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
