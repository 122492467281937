import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>ViziRail - Scheduling and Monitoring Software</title>
      <link rel="canonical" href="https://www.vizirail.com.au/" />
    </Helmet>
  )
}
export default SEO
