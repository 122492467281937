import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import SimpleReactLightbox from 'simple-react-lightbox'
import BackToTop from 'react-back-to-top-button'
import Header from './Header'
import Footer from './Footer'
import SEO from './seo'

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Header />
      <SimpleReactLightbox>
        <main id="main" className="text-gray-900">{children}</main>
        <BackToTop showOnScrollUp showAt={100} speed={1500} easing="easeInOutQuint">
          <div className="back-to-top-button">
            <FontAwesomeIcon icon={faChevronUp} size="sm" />
          </div>
        </BackToTop>
      </SimpleReactLightbox>
      <Footer />
    </>
  )
}

export default Layout
