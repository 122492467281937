import React from 'react'
import { SRLWrapper } from 'simple-react-lightbox'

const options = {
  settings: {
    disablePanzoom: true,
    disableKeyboardControls: true,
    disableWheelControls: true,
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
}

const LightBoxWrapper = ({ children }) => {
  return <SRLWrapper options={options}>{children}</SRLWrapper>
}

export default LightBoxWrapper
