import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import Image from '../../components/Image'

const Header = () => (
  <header className="sticky top-0 bg-white shadow-md z-10">
    <div className="container flex md:flex-row flex-col justify-between items-center mx-auto py-4 md:px-8">
      <div className="flex items-center text-2xl">      
        <div className="w-32 logo-highlight">
          <Link to="/">
            <Image src="logo.PNG" alt="Vizirail" disableLightBox={true} />
          </Link>
        </div>
      </div>
      
      <div className="flex sm:mt-0 mt-2 text-center md:space-x-8 space-x-4 md:pr-4 px-1">
        <Link to="/">Home</Link>
        <div className="dropdown">
          <span className="dropbtn">
            <span>Products &amp; Services</span>
            <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
          </span>
          <div className="dropdown-content">
            <Link to="/vizirail">ViziRail</Link>
            <Link to="/dtpos">DTPOS</Link>
            <Link to="/webportal">ViziRail Web Gateway</Link>
          </div>
        </div>
        <div className="dropdown">
          <span className="dropbtn">
            <span>Company</span>
            <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
          </span>
          <div className="dropdown-content">
            <Link to="/about">About</Link>
            <Link to="/careers">Careers</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  </header>
)

export default Header
